<template>
    <van-nav-bar title="隐私条款" left-arrow @click-left="onClickLeft"></van-nav-bar>

  <div class="privacy-policy">
    <header>
      <h2>隐私政策</h2>
      <p>生效日期：2025年3月11日</p>
    </header>

    <!-- 移除了section标签，直接放置内容 -->
    <h3>1. 引言</h3>
    <p>欢迎使用校易收小应用（以下简称“服务”）。我们非常重视用户的隐私和个人信息安全。本隐私政策解释了我们在您使用服务时收集的信息类型、信息的使用方式以及保护措施。</p>

    <h3>2. 信息存储</h3>
    <p>为了提供我们的服务，我们会通过您的浏览器或设备从本地存储（Local Storage）中存储必要的技术信息。</p>

    <h3>3. 数据安全</h3>
    <p>本应用目前未集成任何第三方服务或广告网络。因此，不存在通过第三方服务间接收集用户信息的情况,我们采取合理的技术和组织措施来保护您的信息免受未经授权的访问、更改、披露或破坏。</p>

    <h3>4. 您的权利</h3>
    <p>根据适用的数据保护法律，您有权要求查看、更新或删除您的个人信息。请通过提供的联系方式与我们联系以行使这些权利。</p>

    <h3>5. 联系我们</h3>
    <p>如果您对本隐私政策有任何疑问，请通过 <a href="mailto:mibaby@miqulai.cn">mibaby@miqulai.cn</a> 联系我们。</p>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: 'PrivacyPolicy',
  setup() {
    const router = useRouter(); //获取值时使用

    const onClickLeft = () => {
      router.go(-1);
    };
    return {
      onClickLeft,
    };
  },
};
</script>

<style scoped>
/* 将原来的内联样式移到这里 */
.privacy-policy {
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* 更改为白色背景 */
  color: #333333; /* 深灰色文字 */
  line-height: 1.6;
  padding: 1em; /* 增加页面内边距 */
}

header {
  background-color: #0056b3;
  color: #ffffff; /* 白色文字 */
  text-align: center;
  padding: 1em;
}

h1{

}
h2 {
  color: #ffffff; /* 标题颜色为深蓝色 */
}

a {
  color: #007bff; /* 链接颜色为蓝色 */
}
</style>